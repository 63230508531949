import { AutoFixHigh, DragIndicatorTwoTone, Loop, PersonPinOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { NationsContext, PreventiviNewContext } from "../../context";
import moment from "moment";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useForm } from "../../hooks";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import { getFlagPath } from "../../utilities";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const Mittente = ({
  indicatoriConfig,
  readOnly,
  oraPronta,
  luogoRitiroVisible,
  riferimentoVisible,
  setMainForm,
  mainForm,
  indirizzoCodificatoDefault,
  accessoriSubtitle
}) => {
  
    const { 
        state:{

            form
            },


     } = useContext(PreventiviNewContext);
  const {
    state: { provincesFrom, nations, loading },
    getProvincesListsByNationFrom,
    getNationsListsData
  } = useContext(NationsContext);
  

  let luogoRitiro = JSON.parse(localStorage.getItem("luogoRitiro"));
  
  const [checked, setChecked] = React.useState(false);
  const [openListLocalita, setOpenListLocalita] = React.useState(false);
  const [animationRotate, setanimationRotate] = React.useState(false);
  const [checkedIndicatori, setCheckedIndicatori] = React.useState([]);
  const [localitaAll, setLocalitaAll] = React.useState(  "" );


  const {
    values: mittenteForm,
    changeHandler,
    touched,
    errors,
    setValues,
  } = useForm(
    {
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCap: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],
    },
    []
  );

  useEffect(() => {
    getNationsListsData();


  }, []);


  useEffect(() => {
    setMainForm({...mainForm,
      DataPronta: mittenteForm.DataPronta,
      OraPronta: mittenteForm.OraPronta,
      MittNazione: mittenteForm.MittNazione,
      MittLocalita: mittenteForm.MittLocalita,
      MittProvincia: mittenteForm.MittProvincia,
      MittCap: mittenteForm.MittCap,
      MittRagSoc: mittenteForm.MittRagSoc,
      MittCodiceIndirizzoCodificato: mittenteForm.MittCodiceIndirizzoCodificato,
      MittIndirizzo: mittenteForm.MittIndirizzo,
      MittPersona: mittenteForm.MittPersona,
      MittTelefono: mittenteForm.MittTelefono,
      MittEmail: mittenteForm.MittEmail,
      MittRiferimento: mittenteForm.MittRiferimento,
      NotaRitiro: mittenteForm.NotaRitiro,
      Orari: mittenteForm.Orari,
      IndicatoriMitt: mittenteForm.IndicatoriMitt,
    });
  }, [mittenteForm]);



  useEffect(() => {
    getProvincesListsByNationFrom(mittenteForm.MittNazione, setOpenListLocalita);
  }, [mittenteForm.MittNazione]);

  useEffect(() => {
    if (luogoRitiroVisible) {


      if (form && form.MittCap && form.MittLocalita && form.MittProvincia){
        setValues({
          ...mittenteForm,
          MittNazione: "IT",
          MittLocalita: form.MittLocalita,
          MittProvincia: form.MittProvincia,
          MittCap: form.MittCap,
        });

        setLocalitaAll(
          form.MittCap +
          "⠀-⠀" +
          form.MittLocalita +
          "⠀-⠀" +
          form.MittProvincia
        );

      }
      else if (luogoRitiro.Indirizzo && luogoRitiro.Localita && luogoRitiro.Cap){
        setValues({
          ...mittenteForm,
          MittNazione: "IT",
          MittLocalita: luogoRitiro.Localita,
          MittProvincia: luogoRitiro.Provincia,
          MittCap: luogoRitiro.Cap,

        });
        setLocalitaAll(
          luogoRitiro.Cap +
            "⠀-⠀" +
            luogoRitiro.Localita +
            "⠀-⠀" +
            luogoRitiro.Provincia
        );
      }
      
    } else {
      setValues({
        ...mittenteForm,
        MittLocalita: mittenteForm.MittLocalita,
        MittProvincia: mittenteForm.MittProvincia,
        MittCap: mittenteForm.MittCap,
      });
      if(mittenteForm.MittCap === "" && mittenteForm.MittLocalita === "" && mittenteForm.MittProvincia  === ""){
        setLocalitaAll("");
      }
      else if( luogoRitiro.Cap === undefined && luogoRitiro.Localita === undefined && luogoRitiro.Provincia === undefined){
        setLocalitaAll('')
      }
      else{
        setLocalitaAll(
          mittenteForm.MittCap +
            "⠀-⠀" +
            mittenteForm.MittLocalita +
            "⠀-⠀" +
            mittenteForm.MittProvincia
        );
      }
    }
  }, [luogoRitiroVisible, nations, loading]);

  const changeValue = (newValue) => {
    setLocalitaAll(newValue);
    if (newValue) {
      setValues({
        ...mittenteForm,
        MittLocalita: newValue.split("⠀-⠀")[1],
        MittProvincia: newValue.split("⠀-⠀")[2] ? newValue.split("⠀-⠀")[2] : "",
        MittCap: newValue.split("⠀-⠀")[0],
      });
    } else {
      changeHandler("MittLocalita", newValue);
    }
  };

  const anagraficheVisible = () => {
    let config = JSON.parse(localStorage.getItem("configurazione"));
    let anagElement ={};
    config.map((element) => {
      if(element.name === 'anagrafiche'){
        anagElement = element;
      }
    });
    return anagElement.visible;   
  }  

  const clearForm = () => {
      setChecked(true);
      setanimationRotate(true);
        setValues({
          ...mittenteForm,
          MittNazione: "IT",
          MittLocalita: "",
          MittProvincia: "",
          MittCap: "",
          MittRagSoc: "",
          MittIndirizzo: "",
          MittPersona: "",
          MittTelefono: "",
          MittEmail: "",
          MittRiferimento: "",
          NotaRitiro: "",
          Orari: "",
        });
        setLocalitaAll("");
        setTimeout(() => {
          setanimationRotate(false);
        }, 1500);
    }

  const defaultMittente = () => {
      setChecked(!checked);
      setanimationRotate(true);
      setTimeout(() => {
        setanimationRotate(false);
      }, 1500);

        setValues({
          ...mittenteForm,
          MittNazione: "IT",
          MittLocalita: luogoRitiro.Localita,
          MittProvincia: luogoRitiro.Provincia,
          MittCap: luogoRitiro.Cap,
          MittRagSoc: luogoRitiro.RagSoc,
          MittIndirizzo: luogoRitiro.Indirizzo,
          MittPersona: luogoRitiro.Persona,
          MittTelefono: luogoRitiro.Telefono,
          MittEmail: luogoRitiro.Email,
          MittRiferimento: "",
          NotaRitiro: "",
          Orari: "",
        });
        setLocalitaAll(
          luogoRitiro.Cap +
            "⠀-⠀" +
            luogoRitiro.Localita +
            "⠀-⠀" +
            luogoRitiro.Provincia
        );   
    
  };



  const changeIndicatoriChecked = (isChecked, code) => {
    if (isChecked) {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
        code,
      ]);
      setValues({
        ...mittenteForm,
        IndicatoriMitt: [
          ...checkedIndicatori.filter((res) => res !== code),
          code,
        ],
      });
    } else {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
      ]);
      setValues({
        ...mittenteForm,
        IndicatoriMitt: [...checkedIndicatori.filter((res) => res !== code)],
      });
    }
  };

 
  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Mittente"}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        subheader={"Dati del mittente"}
        avatar={<PersonPinOutlined />}
        action={
          <Stack direction={"row"} justifyContent="flex-end" spacing={2} p={2}>
            <Button
              color="warning"
              variant="contained"
              size="small"
              disabled={!checked}
              onClick={() => defaultMittente()}
              startIcon={<AutoFixHigh />}
            >
              mittente di default
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={
                animationRotate ? (
                  <Loop
                    color="warning"
                    sx={{
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                ) : (
                  <Loop color="error" />
                )
              }
              //disabled={checked}
              onClick={() => clearForm()}
            >
              Svuota Campi
            </Button>
          </Stack>
        }
      />
      <CardContent>
        <Grid item container spacing={1}>


          <Grid item xs={12} md={6} lg={6} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="select-from-nations">Nazione</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="select-from-nations"
                id="select-from-nations"
                value={mittenteForm.MittNazione || ""}
                label="Nazione"
                onChange={(e) => {
                  setValues({
                    ...mittenteForm,
                    MittNazione: e.target.value,
                    MittLocalita: "",
                    MittProvincia: "",
                    MittCap: "",
                    MittIndirizzo: "",
                    MittRagSoc: "",
                    MittPersona: "",
                    MittTelefono: "",
                    MittCodiceIndirizzoCodificato: "",
                    MittEmail: "",
                    MittRiferimento: "",
                  });
                  setLocalitaAll("");
                }}
              >
                <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                {nations?.map((params, key) => (
                  <MenuItem key={key} value={params.nazioneiso}>
                    <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                      <img
                        loading="lazy"
                        width="20"
                        src={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                            : ""
                        }
                        srcSet={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                            : ""
                        }
                        alt=""
                      />
                      {params.descrizione}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {mittenteForm.MittNazione === "IT" ? (
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                freeSolo
                fullWidth
                forcePopupIcon={openListLocalita}
                value={localitaAll || ""}
                inputValue={localitaAll || ""}
                openOnFocus={openListLocalita}
                onOpen={() => setOpenListLocalita(true)}
                onClose={() => setOpenListLocalita(false)}
                size="small"
                filterOptions={filterOptions}
                id="select-from-provinces"
                groupBy={(option) =>
                  mittenteForm.MittNazione === "IT"
                    ? option.split("⠀-⠀")[1][0]
                    : option[0]
                }
                options={provincesFrom?.map((item) => {
                  return `${item.cap}⠀-⠀${item.localita}${
                    item.provincia ? `⠀-⠀${item.provincia}` : ""
                  }`;
                })}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={uuidv4()}>
                    {option}
                  </Box>
                )}
                loading={provincesFrom.length === 0}
                onInputChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                onChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                disabled={loading || readOnly}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cap - Località - Provincia "
                    sx={{ marginTop: 2 }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            ""
                          )}
                          {params.InputProps.startAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  autoFocus
                  value={mittenteForm.MittLocalita || ""}
                  inputValue={mittenteForm.MittLocalita || ""}
                  size="small"
                  openOnFocus={openListLocalita}
                  onOpen={() => setOpenListLocalita(true)}
                  onClose={() => setOpenListLocalita(false)}
                  id="select-from-provinces"
                  options={provincesFrom?.map((item) => {
                    return item.localita;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  loading={provincesFrom.length === 0}
                  onInputChange={(e, newValue) => {
                    changeHandler("MittLocalita", newValue);
                  }}
                  onChange={(e, newValue) => {
                    changeHandler("MittLocalita", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Località"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  value={mittenteForm.MittCap || ""}
                  inputValue={mittenteForm.MittCap || ""}
                  size="small"
                  id="select-from-provinces"
                  options={provincesFrom.map((item) => {
                    return item.cap;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  onChange={(e, newValue) => changeHandler("MittCap", newValue)}
                  onInputChange={(e, newValue) => {
                    changeHandler("MittCap", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cap"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
 
          

        </Grid>
      </CardContent>
    </Card>
  );
};

export default Mittente;
