import { Grid } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DialogsCustom, FileView, Notifications, ResponsiveAppBar } from "./components";
import BackToTop from "./components/BackToTop";
import MiniDrawer from "./components/MiniDrawer";
import OrdiniMainForm from "./components/OrdiniFormComponent/OrdiniMainForm";
import OrdiniMainFormUpdate from "./components/OrdiniFormComponent/OrdiniMainFormUpdate";
import SpedizioniMainForm from "./components/SpedizioniFromComponent/SpedizioniMainForm";
import SpedizioniMainFormUpdate from "./components/SpedizioniFromComponent/SpedizioniMainFormUpdate";
import { useNotification } from "./hooks";
import useDialogCustuom from "./hooks/useDialogCustuom";
import {
  DashBoard,
  DettaglioIndirizziCodificati,
  DettaglioRitiri,
  DettaglioSpedizioni,
  DettaglioTracking,
  Documenti,
  IndirizziCodificati,
  Login,
  LoginRedirected,
  NotFound,
  Preventivi,
  PublicViewTracking,
  Ritiri,
  Spedizioni,
  Tracking,
  TestFileUpload,
  FUpload,
  PreventiviNew,
  DettaglioPreventivo
} from "./pages";
import MaintenanceMode from "./pages/MaintenanceMode";
import Corrispondente from "./pages/Corrispondente";

const Main = () => {
  const [notifications, closeNotification] = useNotification();
  const [dialog, closeDialog] = useDialogCustuom();
  const { t } = useTranslation("common", { keyPrefix: "mainPage" });

  return (
    <>
      {notifications.length > 0 && (
        <Notifications
          notifications={notifications}
          closeNotification={closeNotification}
        />
      )}
      {dialog.length > 0 && (
        <DialogsCustom
          dialog={dialog}
          closeDialog={closeDialog}
        />
      )}
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<MiniDrawer child={<DashBoard />} />}
          />

          <Route
            path="/maintenance-page"
            element={<MaintenanceMode></MaintenanceMode>}
          />
          <Route
            path="/tracking"
            element={<MiniDrawer child={<Tracking />} />}
          />
          <Route
            path="/public/view/tracking/:idTracking"
            element={
              <Grid container spacing={2}>
                <ResponsiveAppBar />
                <Grid item xs={12} md={12} lg={12} m={10}>
                  <PublicViewTracking />
                </Grid>
              </Grid>
            }
          />
          <Route
            path="/tracking/:idTracking"
            element={
              <MiniDrawer
                child={<DettaglioTracking />}
                titoloPassato={"titoloPassatoTracking"}
              />
            }
          />

          <Route
            path="/redirectedlogin/:username/:pwd"
            element={
              <LoginRedirected/>
            }
          />
          
          <Route path="/prova" element={<BackToTop />} />
          <Route path="/ritiri" element={<MiniDrawer child={<Ritiri />} />} />
          <Route
            path="/ritiri/:idRitiri"
            element={
              <MiniDrawer
                child={<DettaglioRitiri />}
                titoloPassato={"titoloPassatoRitiro"}
              />
            }
          />
          <Route
            path="/ritiri/edit/:idRitiro"
            element={<OrdiniMainFormUpdate />}
          />
          <Route path="/ritiri/new" element={<OrdiniMainForm />} />
          <Route
            path="/spedizioni"
            element={<MiniDrawer child={<Spedizioni />} />}
          />
          <Route
            path="/spedizioni/:idSpedizione"
            element={
              <MiniDrawer
                child={<DettaglioSpedizioni />}
                titoloPassato={"titoloPassatoSpedizioni"}
              />
            }
          />
          <Route path="/spedizioni/new" element={<SpedizioniMainForm />} />
          <Route
            path="/spedizioni/edit/:idSpedizione"
            element={<SpedizioniMainFormUpdate />}
          />
          <Route
            path="/spedizioni/print/:idPrint/:idModStampa"
            element={<FileView />}
          />
          <Route
            path="/indirizzi-codificati"
            element={<MiniDrawer child={<IndirizziCodificati />} />}
          />
          <Route
            path="/indirizzi-codificati/:idIndCod"
            element={
              <MiniDrawer
                child={<DettaglioIndirizziCodificati />}
                titoloPassato={"titoloPassatoAnagrafica"}
              />
            }
          />
          <Route
            path="/documenti"
            element={
              <MiniDrawer
                child={<Documenti />}
                titoloPassato={"titoloPassatoDocumentale"}
              />
            }
          />
          <Route
            path="/documenti/:idDocumento"
            element={
              <MiniDrawer
                child={<FileView />}
                titoloPassato={"titoloPassatoDocumenti"}
              />
            }
          />
          <Route path="/pod/:idPod" element={<FileView />} />
          <Route
            path="/preventivi"
            element={
              <MiniDrawer
                child={<PreventiviNew />}
                titoloPassato={"Nuovo Preventivo"}
              />
            }
          />

          <Route
            path="/corrispondente"
            element={
              <MiniDrawer
                child={<Corrispondente />}
                titoloPassato={"Corrispondente"}
              />
            }
          />

          <Route
            path="/vettore"
            element={
              <MiniDrawer
                child={<Corrispondente />}
                titoloPassato={"Vettore"}
              />
            }
          />

          <Route
            path="/preventivi/preventivocalcolato"
            element={
              <MiniDrawer
                child={<DettaglioPreventivo />}
                titoloPassato={"RiepilogoPrev"}
              />
            }
          />


           <Route path="/uploadfile" element={<FUpload />} /> 

        </Routes>
      </Router>
    </>
  );
};

export default Main;
