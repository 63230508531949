import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentiContext, TrackingContext } from "../context";
import { titlePage } from "../utilities";
import {
  AppBar,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBack, Download, MenuBook } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Player } from "@lottiefiles/react-lottie-player";

const FileView = () => {
  const {
    state: { image, fileName },
    downloadPOD,
    getPrintFile,
  } = useContext(TrackingContext);

  const {
    state: { imageDoc },
    getDocumentFile,
  } = useContext(DocumentiContext);

  const { innerHeight: height } = window;
  const [heightSet, setHeight] = useState(height - 25);

  const [loading, setloading] = useState(false);

  const { idPod, idDocumento, idPrint, idModStampa } = useParams();

  let count = 0;
  const navigate = useNavigate();

  function handleResize() {
    const { innerHeight: height } = window;
    setHeight(height - 71);
  }

  const ref = useRef(null);
  const gridIframe = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (idPod && parseInt(idPod) > 0) {
      titlePage("", "Pod viewer");
      if (count === 0) {
        count = count + 1;
        downloadPOD(idPod);
      }
    }
  }, [idPod]);

  useEffect(() => {
    if(image){
      setTimeout(() => {
        setloading(true);
      }, 4000);
    }else if(image === false) {
      setTimeout(() => {
        setloading(true);
      }, 2000);
    }
  }, [image, loading]);
  
  useEffect(() => {
    if (idPrint && parseInt(idPrint) > 0) {
      titlePage("", "Pagina di Stampa");
      if (count === 0) {
        count = count + 1;
        getPrintFile(idPrint, idModStampa);
      }
    }
  }, [idPrint, idModStampa]);

  useEffect(() => {
    if (idDocumento && parseInt(idDocumento) > 0) {
      titlePage("", `Doc. n° ${idDocumento}`);
      if (count === 0) {
        getDocumentFile(idDocumento);
        setTimeout(() => {
          ref.current.click();
        }, 2000);
        count = count + 1;
      }
    }
  }, [idDocumento]);

  return (
    <div id="over">
      {parseInt(idPod) > 0 ? (
        <Box sx={{ flexGrow: 1 /* overflow:"hidden" */ }}>
          <AppBar position="static" sx={{ flexGrow: 1 }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuBook />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                POD: {fileName}
              </Typography>
              <Button
                ref={ref}
                href={image}
                download={fileName}
                startIcon={<Download />}
                color="inherit"
              >
                Scarica Documento
              </Button>
            </Toolbar>
          </AppBar>
          {image ? (
            <iframe
              ref={gridIframe}
              src={image + "#toolbar=0&navpanes=0"}
              width={"100%"}
              height={heightSet}
              id="print-iframe"
              title="Test"
              frameBorder={0}
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <p>Thi browser does not support PDF!</p>
            </iframe>
          ) : (
            <>
              {(loading && !image)?(
                <Player
                  //src="https://assets1.lottiefiles.com/private_files/lf30_lkquf6qz.json"
                  src="cors_json/lf30_lkquf6qz.json"
                  loop
                  autoplay
                  speed={2}
                  style={{ height: "50%", width: "50%" }}
                />
              ) : (
                <Player
                  // src="https://assets6.lottiefiles.com/private_files/lf30_fup2uejx.json"
                  src="cors_json/lf30_fup2uejx.json"
                  loop
                  autoplay
                  speed={2}
                  style={{ height: "50%", width: "50%" }}
                />
              )}
            </>
          )}
        </Box>
      ) : (
        <></>
      )}

      {parseInt(idPrint) > 0 ? (
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          {loading && image !== null ? (
            <iframe
              ref={gridIframe}
              src={image + "#toolbar=1&navpanes=1"}
              width={"100%"}
              height={heightSet}
              id="print-iframe"
              title="Test"
              frameBorder={0}
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <p>Thi browser does not support PDF!</p>
            </iframe>
          ) : (
            <>
              {loading === true && image === null ? (
                <Player
                  //src="https://assets1.lottiefiles.com/private_files/lf30_lkquf6qz.json"
                  src="cors_json/lf30_lkquf6qz.json"
                  loop
                  autoplay
                  speed={2}
                  style={{ height: "50%", width: "50%" }}
                />
              ) : (
                <Player
                  // src="https://assets6.lottiefiles.com/private_files/lf30_fup2uejx.json"
                  src="cors_json/lf30_fup2uejx.json"
                  loop
                  autoplay
                  speed={2}
                  style={{ height: "50%", width: "50%" }}
                />
              )}
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
      {parseInt(idDocumento) > 0 ? (
        <>
          <Stack direction={"row"} spacing={2} mb={5}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowBack />}
              onClick={() => navigate("/documenti")}
            >
              Torna ai documenti{" "}
            </Button>
          </Stack>

          <Typography fontSize={18} fontWeight={"bold"}>
            Nome file: "{imageDoc.fileName.toUpperCase()}"
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <Typography>
              Il file verrà scaricato automaticamente... attendere! oppure
              clicca qui
            </Typography>
            <Button
              ref={ref}
              //onClick={myfunc}
              href={imageDoc.base64}
              download={imageDoc.fileName}
              variant="contained"
              startIcon={<Download />}
            >
              Scarica Documento
            </Button>
          </Stack>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FileView;
