import React, {useState, useContext, useEffect} from 'react'
import {
    Button,
    CardHeader,
    CardContent,
    Stack,
    IconButton,
    Tooltip, 
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Box,
    Typography,
  } from "@mui/material";  
  import rehypeRaw from "rehype-raw";
  import {
    Delete,
    DocumentScanner,
    Download
  } from "@mui/icons-material";
import ReactMarkdown from 'react-markdown';



const UploadDialog = ({
    open,
    setOpen,
    testDoc,
    postFile,
    dialogTitle,
    navigate,
    numRif,
    //mode = "" : default: csv, xls, txt
    //mode = "POD" : ".pdf, image/*, .png, .jpg"
    mode
}) => {
    const [fileName, setFileName] = useState("")
    //encoded file buffer
    const [base64File, setbase64File] = useState("");


    useEffect(() =>{
        if (dialogTitle === ""){
            dialogTitle = "Caricamento file"
        }
    },[]);

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        //fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);          
        };
        
        fileReader.onerror = (error) => {
          reject(error);
        };

        fileReader.readAsDataURL(file);
      });
    };
  

      const constSingleBase64 = async (file) => {
        const base64 = await convertBase64(file);
        setbase64File(base64);
        setFileName(file.name);
        //changeHandler("UploadFile", fileBase64);
      };

      const constMultipleBase64 = async (file) => {
        let fileBase64 = base64File;
        let fileNameAppoggio = fileName;
        const base64 = await convertBase64(file);
        fileBase64.push(base64);
        fileNameAppoggio.push(file.name);
        setbase64File(fileBase64);
        setFileName(fileNameAppoggio);
      };


      const handleFileRead = (event) => {
        let file;
        for (let i = 0; i < event.target.files.length; i++) {
          file = event.target.files[i];
          constSingleBase64(file);
        }
      };
    
      const annullaUpload = () => {
        setbase64File("");
        setFileName("");
      };

      const handleClose = () => {
        setOpen(false);
        annullaUpload()
      };

      const annullaEChiudi = () => {
        annullaUpload();
        handleClose()
      };

      const handleUpload = () => {
        postFile(base64File, fileName);
        annullaUpload();
        setOpen(false)
        //window.location.reload(false);
      }

  return (
    <div>
        <Dialog open={open}  onClose={() => annullaEChiudi()}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
            {numRif && numRif !== '' ?(
              <CardContent>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                >
                  {`Spedizione: <b>${numRif}</b> `}
                </ReactMarkdown>
              </CardContent>            
            ):(<></>)}
            <CardHeader          //title="Documenti"
              subheader={
                fileName!==""
                ? `${fileName}`
                : "Nessun file selezionato!"
            }
            subheaderTypographyProps={{
                fontWeight: "bold",
            }}
            titleTypographyProps={{
                fontWeight: "bold",
                fontSize:18
            }}
            action={
                <Tooltip title="Cancella i dati caricati">
                <span>
                  <IconButton
                      color="error"
                      size="large"
                      onClick={() => annullaUpload()}
                      disabled={base64File===""}
                  >
                  <Delete />
                  </IconButton>
                </span>
                </Tooltip>
            }
            avatar={<DocumentScanner />}
            />


            </DialogContent>
            <DialogActions>
                
                <input
                  id="icon-button-photo"
                  onChange = {(e) => handleFileRead(e)}
                  type     = "file"
                  accept   = { mode === "POD" ? ( ".pdf, image/*, .png, .jpg") : (".csv, text/plain, .xls, .xlsx") }
                  hidden
                />

                <label htmlFor="icon-button-photo">
                  <Stack direction={"row"} spacing={2}>
                      <Button component="span" variant="contained">
                        Scegli file
                      </Button>                  
                      <Button variant="contained" color="success" disabled={base64File===""} onClick={() => handleUpload()}>
                        Carica
                      </Button>
                      <Button 
                        variant="contained"
                        color="warning" 
                        onClick={() => handleClose()}
                        //disabled={base64File===""}
                      >

                        Annulla Caricamento
                      </Button>
                  </Stack>
                  {testDoc?.base64 && testDoc?.base64 !== "" ?(
                    <Box textAlign="center">
                      <Button 
                        variant   = "text"  
                        href      = { testDoc?.base64? ( testDoc?.base64 ) : ( "" ) }
                        download  = { testDoc?.filename && testDoc?.base64 ? (testDoc?.filename) : ( "" ) }
                        startIcon = { <Download /> }
                        color     = "inherit"
                        sx        = {{ mt:2 }} 
                      >
                        Scarica file di esempio
                      </Button>
                    </Box>
                    ):(<></>)
                  }
                </label>            
            </DialogActions>
         </Dialog>
    </div>
  )
}

export default UploadDialog